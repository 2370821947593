<template>
  <div id="portfolio">
    <img
      id="artist-portrait"
      src="../../assets/imgs/press/artist_portrait_1.png"
    />
    <div class="container">
      <h1>XIN</h1>
      <h4>WRITER, SINGER, GUITARIST</h4>
      <p id="subtitle">{{ overview[this.$route.params.type] }}</p>
      <div v-if="this.$route.params.type == 'tour' || true">
        <h5>PERFORMANCE HIGHLIGHTS</h5>
        <div id="showreel-cover" @click="this.showPopup('#showreel')">
          <img src="../../assets\imgs\press\performer.jpg" />
          <i class="fa-regular fa-circle-play"></i>
        </div>
      </div>
      <Projects
        v-if="release.length"
        projectsTitle=""
        :projects="release"
      ></Projects>
      <Projects projectsTitle="EXPERIENCE" :projects="pastProjs"></Projects>
      <VideoClips
        projectsTitle="VIDEO CLIPS"
        :projects="videoClips"
      ></VideoClips>
      <div id="teaching">
        <h5>EXTRA</h5>
        <ul id="additional-skills">
          <li>Proficient with Cubase Pro in both studio and live settings</li>
          <li>Guitar tech skills from hands-on experience building guitars</li>
          <li>
            Developed online educational tools for musicians and sound-engineers
            at <a href="https://www.soundgym.co" target="blank">SoundGym</a>,
            <a href="https://www.tonegym.co" target="blank">ToneGym</a>, and
            <a href="https://www.guitargym.co" target="blank">GuitarGym</a>
          </li>
          <li>Tought private guitar lessons for 5 years</li>
          <li>
            Gear- Custom built 7-string guitar, Neural DSP Quad Cortex, custom
            wireless in-ear monitors, and wireless guitar system
          </li>
          <li>Fluent in English and Hebrew, basic proficiency in German</li>
          <li>Basic experience in playing the Trumpet, Cello, and Violin</li>
        </ul>
      </div>
      <div id="contact">
        <h5>CONTACT</h5>
        <p>
          Berlin, Germany
          <br />
          <a href="mailto:xin4tus@gmail.com" target="blank"
            >xin4tus@gmail.com</a
          >
          <br />
          <a href="tel:+4915204148920" target="blank">WA: +49 1520 4148920</a>
          <br />
          <a href="https://www.instagram.com/xin.4tus/" target="blank"
            >IG: @xin.4tus</a
          >
          <br />
          <a href="https://www.facebook.com/XINNERS/" target="blank"
            >FB: @XINNERS</a
          >
        </p>
      </div>
    </div>
  </div>
  <ShowreelPopup></ShowreelPopup>
</template>

<script>
import App from "../../App.vue";
import Projects from "@/components/press/Projects.vue";
import VideoClips from "@/components/press/VideoClips.vue";
import ShowreelPopup from "@/components/press/ShowreelPopup.vue";
export default {
  data() {
    return {
      overview: {
        tour: "I'm Amit Fotus, a dynamic guitarist, vocalist, and growler with 8 years of professional experience in metal and pop genres, seeking opportunities to tour across Europe and the USA. Aiming to bring versatility, positive energy, and a strong stage presence to high-level bands and artists",
        collab:
          "My name is Amit Fortus. My mission is to bring some hope and positivity to the alt scene",
        service: "",
      },
      release: [
        // {
        //   title: "unbroken",
        //   sub: "",
        //   desc: ``,
        //   imgPath: "/releases/unknown/cover-art.jpg",
        //   audioPath: false,
        // },
      ],
      pastProjs: [
        {
          title: "Amorphis",
          imgPath: "/press/amorphis.jpg",
          link: "https://www.youtube.com/playlist?list=OLAK5uy_nKp_yMRKDsyQlMuz6aK_OoASNeOkRD-2o",
          sub: "Choir vocalist - 2017",
          desc: "Recorded bass, bariton and tenor choir parts for the album “Queen of Time”",
        },
        {
          title: "Orphaned Land",
          imgPath: "/press/orphaned_land.jpg",
          link: "https://www.youtube.com/playlist?list=OLAK5uy_kaLKwFIXKm-nyr-IDyyvn0nIZinxG5IhQ",
          sub: "Choir vocalist - 2017",
          desc: "Toured Israel and recorded bass, bariton and tenor choir parts for the album “Unsung Prophets and Dead Messiahs”",
        },
        {
          title: "Oceans on Orion",
          imgPath: "/press/oceans_on_orion.jpg",
          link: "https://www.youtube.com/watch?v=WrqpXlOr1WQ&list=OLAK5uy_lUGocuijRB_sK_FLokmMTH2CJss2r3vqo&index=7",
          sub: "Guitarist, Growler, Writer - 2019 - 2022",
          desc: "Toured Israel, recorded guitars, growls and co-produced on the album “Start From Nothing”",
        },
        {
          title: "selfless",
          imgPath: "/releases/selfless/cover-art.jpg",
          link: "https://www.youtube.com/watch?v=5Uri9nve7BU",
          sub: "Single - 2023",
          desc: `Wrote, produced and recorded all parts`,
        },
        {
          title: "believe",
          imgPath: "/releases/believe/cover-art.jpg",
          link: "https://www.youtube.com/watch?v=sT90jSoG_nc",
          sub: "Single - 2023",
          desc: `Wrote, produced and recorded all parts`,
        },
        {
          title: "flow",
          imgPath: "/releases/flow/cover-art.jpg",
          link: "https://www.youtube.com/watch?v=loLzq5ZDblo",
          sub: "Single - 2023",
          desc: `Wrote, produced and recorded all parts`,
        },
        {
          title: "Shiran",
          imgPath: "/press/shiran_band.jpg",
          link: "https://www.youtube.com/watch?v=iRfUOT6fIUI&list=PLoTuRXU0GlOkMELP4So9P6ows8MN0IwD6",
          sub: "Rhythm guitarist - 2018",
          desc: "Won the Israeli metal battle and performed at Wacken Open Air festival",
        },
        {
          title: "Scardust",
          imgPath: "/press/scardust.jpg",
          link: "https://www.youtube.com/watch?v=1asYdGLXM_s&list=OLAK5uy_nDaZq5djRldbXq0c_mYVQqf8VxFBAcX8E",
          sub: "Choir vocalist - 2017",
          desc: "Toured Israel and recorded bass, bariton and tenor choir parts for the album “Sands of Time”",
        },
        {
          title: "Infy Snow",
          imgPath: "/press/infy.jpg",
          link: "https://www.youtube.com/watch?v=QnFSjrdddo4&list=PLuBDpApqtzN9JIn46t6DAT6gWDpwBPKCf&index=4",
          sub: "Lead guitarist - 2016 - 2018",
          desc: "Rhythm guitarist on the “A Mortal's Tear” album tour",
        },
        {
          title: "A New You",
          imgPath: "/press/a_new_you.jpg",
          link: "https://www.youtube.com/watch?v=aEpufSC2cYc&list=OLAK5uy_kKUj1BPICE5GBXmGbMfAEGOBEuj-AUq94&index=8",
          sub: "Guitarist - 2022 - 2023",
          desc: "Lead guitarist for the “Someone Out There” album tour",
        },
        {
          title: "Hartsbane",
          imgPath: "/press/hartsbane.jpg",
          link: "https://www.youtube.com/watch?v=OR0udWvgXbE&list=OLAK5uy_mvkuD9faTN-zltZ6bLbxJRTNtI8AIBOPI&index=3",
          sub: "Guitarist, Cubase tech - 2017 - 2019",
          desc: "Toured Israel, UK, and released the EP “As Summer Dies”",
        },
        {
          title: "Merakey",
          imgPath: "/press/merakey.jpg",
          link: "https://www.youtube.com/watch?v=UFqjKso_c8g",
          sub: "Artist - 2017 - 2018",
          desc: "Writer, guitarist and producer on the EP “Surroundings”",
        },
        {
          title: "Hellscore",
          imgPath: "/press/hellscore.jpg",
          link: "https://www.youtube.com/@HellscoreACappella",
          sub: "Choir vocalist - 2017 - 2018",
          desc: "Toured Israel and recorded bass, baritone and tenor choir parts",
        },
        {
          title: "Junkiez",
          imgPath: "/press/junkiez.jpg",
          link: "unknown",
          sub: "Guitarist - 2016 - 2018",
          desc: "Toured Israel and recorded guitars",
        },
        {
          title: "Kadima",
          imgPath: "/press/kadima.jpg",
          link: "https://youtu.be/lZLa9V9EbLc?t=153",
          sub: "Guest lead guitarist - 2020",
          desc: "Guest solo appearance on the song “Lights and Vessels”",
        },
      ],
      videoClips: [
        {
          title: "XIN - Selfness",
          sub: "Video producer, co-scriptwriter, assistant director, lead cast",
          start: 0,
          end: 9999,
          youtubeSerial: "5Uri9nve7BU",
        },
        {
          title: "XIN - Believe",
          sub: "Video producer, co-scriptwriter, assistant director, lead cast, editor",
          start: 0,
          end: 9999,
          youtubeSerial: "sT90jSoG_nc",
        },
        {
          title: "XIN - Flow",
          sub: "Video producer, scriptwriter, assistant director, lead cast, editor",
          start: 0,
          end: 9999,
          youtubeSerial: "loLzq5ZDblo",
        },
        {
          title: "Oceans On Orion - Love",
          sub: "Lead cast",
          start: 53,
          end: 9999,
          youtubeSerial: "WrqpXlOr1WQ",
        },
        {
          title: "Live at Wacken Open Air - Shiran Band",
          sub: "Playing at W.O.A, Representing Israel and reaching 6th place at the 2018 Metal Battle finals",
          start: 97,
          end: 9999,
          youtubeSerial: "wOnq67Vk4bA",
        },
        {
          title: "Oceans On Orion - Tomorrow's Rain",
          sub: "Lead cast",
          start: 0,
          end: 9999,
          youtubeSerial: "iiXNRhmTFxM",
        },
        {
          title: "Oceans On Orion - Weaponized Misery",
          sub: "Lead cast",
          start: 72,
          end: 9999,
          youtubeSerial: "IQAWInKlT0g",
        },
      ],
    };
  },
  components: {
    Projects,
    VideoClips,
    ShowreelPopup,
  },
  methods: {
    showPopup: App.showPopup,
  },
};
</script>

<style lang="scss" scoped>
#portfolio {
  background-color: $defaultBG;
  min-height: $vh;
  h1,
  h4 {
    text-align: center;
  }
  #artist-portrait {
    position: fixed;
    bottom: 0;
    right: -12%;
    @media only screen and (min-height: 400px) {
      height: 320px;
    }
    @media only screen and (min-height: 500px) {
      height: 400px;
    }
    @media only screen and (min-height: 600px) {
      height: 480px;
    }
    @media only screen and (min-height: 700px) {
      height: 560px;
    }
    @media only screen and (min-height: 800px) {
      height: 640px;
    }
    @media only screen and (min-height: 900px) {
      height: 720px;
    }
    @media only screen and (min-height: 1000px) {
      height: 800px;
    }
    @media only screen and (min-height: 1200px) {
      height: 960px;
    }
    @media only screen and (min-height: 1400px) {
      height: 1120px;
    }
    @media only screen and (min-height: 1600px) {
      height: 1280px;
    }
    @media only screen and (min-height: 1800px) {
      height: 1440px;
    }
    @media only screen and (min-height: 2000px) {
      height: 1600px;
    }
    @media only screen and (min-height: 2400px) {
      height: 1920px;
    }
    @media only screen and (min-height: 2800px) {
      height: 2240px;
    }

    @media only screen and (min-width: $minTabletW) {
      right: -4%;
    }
    @media only screen and (min-width: $minLaptopW) {
      right: 0%;
    }
    @media only screen and (min-width: $mindesktopW) {
      right: 5%;
    }
  }
  .container {
    position: relative;
    height: fit-content;
    margin-left: 5%;
    padding: unset;
    max-width: 70%;

    h1 {
      padding-top: 50px;
      font-size: 4rem;
    }
    h4 {
      margin-bottom: 1.5rem;
    }
    h5 {
      margin-bottom: 2%;
      font-size: 1.3rem;
    }
    #subtitle {
      margin-bottom: 2rem;
      text-align: left;
    }
    #additional-skills {
      padding-left: 1rem;
    }
    #teaching {
      text-align: start;
      padding-bottom: 30px;
    }
    #contact {
      max-width: 66.6%;
      text-align: start;
      padding-bottom: 30px;
    }
  }
  #showreel-cover {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
    @media only screen and (min-width: $minTabletW) {
      width: 80%;
    }
    @media only screen and (min-width: $minLaptopW) {
      width: 60%;
    }
    @media only screen and (min-width: $mindesktopW) {
      width: 40%;
    }
    filter: brightness(100%) sepia(50%);
    &:hover {
      img {
        filter: brightness(75%) sepia(100%);
      }
      .fa-circle-play {
        color: lightgrey;
      }
    }
    .fa-circle-play {
      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>