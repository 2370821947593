<template>
  <div id="release" :filePath="`/releases/` + this.releaseID + `/cover-bg.jpg`">
    <img id="release-bg" />
    <div class="container">
      <h2 id="xin-presents">XIN presents</h2>
      <h1 id="title">{{ releases[this.releaseID].title.toUpperCase() }}</h1>
      <!-- <img id="logo" src="../../logos/XIN.ico" /> -->
      <!-- <audio v-if="releases[this.releaseID].audioPath" controls>
        <source
          :filepath="releases[this.releaseID].audioPath"
          type="audio/mp3"
        />
        Your browser does not support the audio tag.
      </audio> -->
      <div id="video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/loLzq5ZDblo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div id="info">
        <h5>Genre:</h5>
        <p id="about-track">{{ releases[this.releaseID].genre }}</p>
        <h5>About XIN:</h5>
        <p id="about-xin">{{ aboutXIN }}</p>
        <h5>About the track:</h5>
        <p id="about-track">{{ releases[this.releaseID].aboutTrack }}</p>
        <h5>About the artist:</h5>
        <p id="about-artist">{{ releases[this.releaseID].aboutArtist }}</p>
        <h5>Cover:</h5>
        <img
          id="cover-art"
          :filePath="`/releases/` + this.releaseID + `/cover-art.jpg`"
        />
        <h5>Gallery:</h5>
        <div id="gallery">
          <img
            v-for="img in releases[this.releaseID].gallery"
            :key="img"
            :filePath="img"
          />
        </div>
        <h5>Contact & Links:</h5>
        <p id="links">
          <a href="mailto:xin4tus@gmail.com">Email</a>
          &nbsp;
          <a href="https://www.instagram.com/xin.4tus">Instagram</a>
          &nbsp;
          <a href="https://www.facebook.com/XINNERS">Facebook</a>
          &nbsp;
          <a
            href="https://open.spotify.com/artist/0VALQPZCUveeKSuT6EQlVz?si=iAhrd2jrSmiPEh86VFmHHg"
            >Spotify</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      aboutXIN:
        "XIN is here to present a new mentality to the sadness and depression-flooded alt-scene audience. XIN preaches positivity and hope even through our everyday struggles.",
      releases: {
        flow: {
          title: "flow",
          aboutTrack:
            "The debut release for XIN, Flow is a chill, melodic, and uplifting track about letting go of the need to know and control our fates and futures",
          aboutArtist:
            "After touring around the world and playing at festivals such as Wacken Open Airת guitarist for numerous metal bands, Fortus decided to focus XIN, his solo project more on pop music. He has worked with artists such as Amorphis (410K Facebook followers), Orphaned Land (2.2M Youtube views), and Richard Fortus of Guns 'n Roses (148K Instagram followers)",
          audioPath: "/flow.mp3",
          genre: "chill, pop, alternative, rock",
          gallery: [
            "/releases/flow/gallery_1.jpg",
            "/releases/flow/gallery_2.jpg",
            "/releases/flow/gallery_3.jpg",
            "/releases/flow/gallery_4.jpg",
            "/releases/flow/gallery_5.jpg",
            "/releases/flow/gallery_6.jpg",
            "/releases/flow/gallery_7.jpg",
            "/releases/flow/gallery_8.jpg",
            "/releases/flow/gallery_9.jpg",
            // "/releases/flow/gallery_10.jpg",
            // "/releases/flow/gallery_11.jpg",
            // "/releases/flow/gallery_12.jpg",
          ],
        },
        twinkle: {
          title: "twinkle",
          sub: "Don't Lie. You'll Only Make Me Cry",
          desc: `This song is still in the making.
Its about two lovers that can't find a way to both show and receive love from each other.
They are both too stuck up in showing their need for love, instead of showing the love they already have for the other one.
If they would only listen to their partner's call, they would see they are so close to fulfilling their mutual love.`,
          lyrics: `(verse 1 - male vox)
  It's dark
  Yet I can see you
  A light
  That tells me to stay
  So far
  And out of reach
  But too close
  To look away

(pre-chorus)
  I wish I could defy
  And finally realize your lie
  You never cared to see I was there
  Just blindly lead me to despair

(chorus)
  Don't lie
  You only make me cry
  How can I
  Still make you see me try

(verse 2 - female vox)
  It's dark
  So I shine for you
  In hope
  You will find me soon
  Cause I feel
  So alone and lost
  And You're gone

(pre-chorus)
  I shine my light for you
  But I can’t feel that you do too
  You never cared to show me you’re there
  Just blindly follow to despair

(chorus)
  Don't lie
  You only make me cry
  How can I
  Still show you that I try

(bridge/c-part - both voices back and forth)
...

(chorus - both voices in harmony)
...`,
          imgPath: "/releases/unknown/cover-art.jpg",
          audioPath: "/twinkle-demo.mp3",
        },
      },
    };
  },
  methods: {
    loadImages: function () {
      var divs = [];
      divs.push(document.querySelector("#release"));
      divs.forEach((div) => {
        var filePath = div.getAttribute("filepath");
        div.style.backgroundImage =
          "url(" + require("../../assets/imgs" + filePath) + ")";
      });

      var imgEls = [];
      imgEls.push(document.querySelector("img#cover-art"));
      imgEls.push(...document.querySelectorAll("#gallery>img"));
      imgEls.forEach((elImg) => {
        var filePath = elImg.getAttribute("filepath");
        elImg.src = require("../../assets/imgs" + filePath);
      });
    },
    loadAudios: function () {
      document
        .querySelectorAll("#release audio > source")
        .forEach((elAudio) => {
          var filePath = elAudio.getAttribute("filepath");
          elAudio.src = require("../../assets/audio" + filePath);
        });
    },
  },
  created() {
    this.releaseID = this.$route.params.releaseID;
  },
  mounted() {
    this.loadImages();
    this.loadAudios();
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $defaultColor;
  font-weight: 400;
}
#release {
  text-align: center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  #release-bg {
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    // bottom: 0;
    right: 0%;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
      width: 100%;
    }
  }
  #info {
    background-color: $matte;
    padding: 5%;
    margin-bottom: 5%;
  }
  audio {
    height: 30px;
    margin-bottom: 20px;
  }
  #video {
    max-width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 40px;
    @media only screen and (min-width: $minTabletW) {
      max-width: 100%;
    }
    @media only screen and (min-width: $minLaptopW) {
      max-width: 70%;
      padding-bottom: calc(56.25% * 0.7);
    }
    @media only screen and (min-width: $mindesktopW) {
      max-width: 60%;
      padding-bottom: calc(56.25% * 0.6);
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  h2#xin-presents {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 3rem;
  }
  h1#title {
    margin-bottom: 30px;
    font-size: 4.5rem;
  }
  #cover-art {
    margin-bottom: 20px;
    max-width: 80%;
    @media only screen and (min-width: $minTabletW) {
      max-width: 60%;
    }
    @media only screen and (min-width: $minLaptopW) {
      max-width: 45%;
    }
  }
  #gallery {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4%;
  }
  h5 {
    font-weight: 500;
    text-align: left;
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 40px;
    white-space: pre-wrap;
    text-align: left;
  }
}
</style>