<template>
  <div id="video-clips">
    <h5>{{ projectsTitle }}</h5>
    <div id="grid">
      <div v-for="videoClip in projects" :key="videoClip.title" class="cell">
        <div id="youtube-embed-container" class="hover-filter">
          <iframe
            :src="
              'https://www.youtube.com/embed/' +
              videoClip.youtubeSerial +
              '?start=' +
              videoClip.start +
              '&end=' +
              videoClip.end
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
        <h4 class="title">{{ videoClip.title.toUpperCase() }}</h4>
        <p class="sub">{{ videoClip.sub }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectsTitle: String,
    projects: Object,
  },
};
</script>

<style lang="scss" scoped>
* {
  background-color: unset;
}
h5 {
  margin-bottom: 2%;
  font-size: 1.3rem;
}
#video-clips {
  max-width: 100%;
  text-align: start;
  margin-bottom: 30px;
  #grid {
    display: grid;
    grid-template-columns: 12fr;
    grid-gap: 25px;
    .cell {
      display: flex;
      flex-direction: column;
      align-items: left;
      .title {
        font-size: 0.75rem;
        font-weight: bold;
        margin-bottom: 2%;
      }
      .sub {
        font-size: 0.7rem;
        font-weight: 600;
      }
      #youtube-embed-container {
        width: 80%;
        position: relative;
        padding-bottom: calc(56.25% * 0.8);
        margin-bottom: 2%;
        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media only screen and (min-width: $minLaptopW) {
    #grid {
      grid-template-columns: 6fr 6fr;
    }
  }
}
</style>