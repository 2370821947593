<template>
  <!-- <div id="bg">
    <img src="@/assets/logos/XIN_BLACK.png" />
  </div> -->
  <div id="error-message" class="container">
    <h2 id="four-o-four">Error 404</h2>
    <h2 id="message">This page doesn't exist</h2>
    <h4 id="warning">Don't fuck with XIN. Hackers will be banned!</h4>
  </div>
</template>

<script>
export default {
  name: "Unknown",
};
</script>


<style lang="scss" scoped>
#error-message {
  padding-top: 5%;

  #four-o-four {
    font-size: 4rem;
    margin-bottom: 3%;
  }

  #message {
    font-size: 2rem;
  }

  #warning {
    font-size: 1.5rem;
  }
}

#bg {
    img {
    height: 10vh;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
</style>