<template>
  <!-- <div id="bg">
    <img src="@/assets/logos/XIN_BLACK.png" />
  </div> -->
  <div id="error-message" class="container">
    <h2 id="message">This page is under development</h2>
    <h4 id="warning">Please don't fuck around with the website.</h4>
  </div>
</template>

<script>
export default {
  name: "Development",
};
</script>


<style lang="scss" scoped>
#error-message {
  padding-top: 5%;

  #message {
    font-size: 4rem;
    margin-bottom: 3%;
  }

  #warning {
    font-size: 1.5rem;
  }
}

#bg {
    img {
    height: 10vh;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
</style>