import { createWebHistory, createRouter } from "vue-router";
// import Home from "../views/Home.vue";
import Shop from "../views/Shop.vue";
import Tour from "../views/Tour.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import Portfolio from "../views/press/Portfolio.vue";
import Release from "../views/press/Release.vue";
import Linktree from "../views/Linktree.vue";
import Unknown from "../views/Unknown.vue";

var isLoggedIn = false;
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/linktree'
    // component: Home,
    // meta: {
    //   requireLogin: true,
    // },
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/tour",
    name: "Tour",
    component: Tour,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/press",
    name: "Press",
    redirect: "/press/portfolio",
  },
  {
    path: "/press/portfolio",
    name: "PressPortfolio",
    redirect: "/press/portfolio/tour",
  },
  {
    path: "/press/portfolio/:type",
    name: "Portfolio",
    component: Portfolio,
    meta: {
      showHeader: false,
      title: 'XIN portfolio'
    },
  },
  {
    path: "/press/release/:releaseID",
    name: "release",
    component: Release,
    meta: {
      showHeader: false,
    },
  },
  {
    path: "/linktree",
    name: "linktree",
    component: Linktree,
    meta: {
      showHeader: false,
      title: "XIN Linktree", 
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Unknown",
    component: Unknown,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((route) => {
  document.title = route.meta.title ? route.meta.title : 'XIN';
  if (route.meta.requireLogin && !isLoggedIn) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: route.fullPath },
    }
  }
});

export default router;