// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// Firebase
import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyDprU9o9R4FIwzI3QBT2cb-NAwgQLV-XU8",
    authDomain: "xin-v1.firebaseapp.com",
    projectId: "xin-v1",
    storageBucket: "xin-v1.appspot.com",
    messagingSenderId: "334016545616",
    appId: "1:334016545616:web:b6ffe1737e9a02b05ce255",
    measurementId: "G-ZDG9RNTYLP"
};
initializeApp(firebaseConfig);

// Clarity
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "kkp3q1tn0g");

// Vue
import { createApp } from 'vue';
import VueHead from 'vue-head'
import VueApp from './App.vue';
import VueRouter from './router';
const app = createApp(VueApp);
app.use(VueHead).use(VueRouter).mount('#app');