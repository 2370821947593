<template>
  <div id="login">
    <input type="email" placeholder="Email" v-model="email" />
    <input type="text" placeholder="Password" v-model="pw" />
    <button @click="doLogin">Join the XINNERS</button>
    <button @click="doGoogleLogin">Join with Google</button>
    <router-link :to="{ name: 'Signup' }">New here?</router-link>
  </div>
  <Development></Development>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Development from "@/components/Development.vue";


export default {
  data() {
    return {
      email: "",
      pw: "",
    };
  },
  components: {
    Development,
  },
  methods: {
    doGoogleLogin() {
      console.log("google log in tutu");
      console.log("email:", this.email, "password:", this.pw);
    },
    doLogin() {
      signInWithEmailAndPassword(getAuth(), this.email, this.pw)
        .then((data) => {
          console.log("data from signInWithEmailAndPassword:", data);
          this.$router.push("/");
        })
        .catch((error) => {
          console.log("errorCode:", error.code);
          alert(error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  #login {
    display: none;
  }
</style>