<template>
  <img id="bg-img" src="../assets/imgs/linktree/bg.jpg" />
  <div class="container center-margin text-center">
    <div style="text-align: center">
      <img
        id="profile-pic"
        class="really-snob"
        alt="XIN profile picture"
        :filepath="this.profilePic.imgPath"
      />
      <h6 id="name">Hi, I'm XIN</h6>
    </div>

    <h4 class="snob">FOLLOW ME</h4>
    <a href="https://instagram.com/xin.4tus" class="link" target="_blank">INSTAGRAM</a>
    <br />
    <a href="https://www.facebook.com/XINNERS" class="link" target="_blank">FACEBOOK</a>
    
    <h4 class="snob">WATCH ME</h4>
    <a href="https://www.youtube.com/@xinofficial" class="link" target="_blank">YOUTUBE</a>

    <h4 class="snob">LISTEN TO ME</h4>
    <a href="https://open.spotify.com/artist/0VALQPZCUveeKSuT6EQlVz" class="link" target="_blank">SPOTIFY</a>
    <br />
    <a href="https://music.apple.com/us/artist/xin/1663853929" class="link" target="_blank">APPLE MUSIC</a>
    <br />
    <a href="https://music.youtube.com/channel/UCxj5_BIV0DSeaiLt8mYZLZQ" class="link" target="_blank">YOUTUBE MUSIC</a>
    <br />
    <a href="https://music.amazon.com/artists/B00ARSCVNY/xin" class="link" target="_blank">AMAZON MUSIC</a>
    <br />
  </div>
  <br />
  <br />
</template>

<script>
import App from "../App.vue";
export default {
  data() {
    return {
      profilePic: {
        imgPath: "/linktree/logo.jpg",
      },
    };
  },
  methods: {
    loadProfileImage: () => {
      App.loadImages("img#profile-pic");
    },
  },
  mounted() {
    this.loadProfileImage();
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $defaultColor;
}

#bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0%;
  position: fixed;
  z-index: -1;
  filter: brightness(50%) contrast(110%);
}

#profile-pic {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

#name {
  font-weight: bolder;
}

.link {
  width: 100%;
  display: block;
  text-align: center;
  font-weight: bolder;
  text-decoration: none;
  padding: 1.4%;
  border-radius: 4px;
  background-color: rgba(62, 62, 62, 0.367);
  &:hover {
    background-color: rgba(80, 80, 80, 0.494);
  }
}

.margin-top-2 {
  margin-top: 32px;
}

.bottom span {
  color: #ed4d82;
}

.bottom svg {
  stroke: #ed4d82;
  fill: #ed4d82;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
</style>