<template>
  <!-- <MyHeader></MyHeader> -->
  <router-view />
</template>

<script>
// import MyHeader from "/components/MyHeader.vue";

export default {
  name: "App",
  // components: {
  //   MyHeader,
  // },
  closePopup: () => {
    document.querySelectorAll(".popup, .blur-screen").forEach((el) => {
      el.classList.add("hide");
    });
  },
  showPopup: (selector) => {
    document
    .querySelectorAll(".popup" + selector + ", .blur-screen")
    .forEach((el) => {
      el.classList.remove("hide");
    });
    // history.pushState(null, null, 'performer')
  },
  loadImages: (selector) => {
    document.querySelectorAll(selector).forEach((elImg) => {
      var filePath = elImg.getAttribute("filepath");
      elImg.src = require("./assets/imgs" + filePath);
    });
  },
  loadAudios: (selector) => {
    document
      // .querySelectorAll("#projects .cell audio > source")
      .querySelectorAll(selector)
      .forEach((elAudio) => {
        var filePath = elAudio.getAttribute("filepath");
        elAudio.src = require("./assets/audio" + filePath);
      });
  },
};
</script>