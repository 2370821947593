<template>
  <div id="projects">
    <h5>{{ projectsTitle }}</h5>
    <div id="grid">
      <div v-for="project in projects" :key="project.title" class="cell">
        <a :href="project.link" class="project-link" target="blank">
          <h4 class="title">{{ project.title.toUpperCase() }}</h4>
          <div class="img-and-text">
            <img :filepath="project.imgPath" class="hover-filter" />
            <div class="details">
              <p class="sub">{{ project.sub }}</p>
              <p class="desc" v-html="project.desc"></p>
            </div>
          </div>
        </a>
        <audio v-if="project.audioPath" controls>
          <source :filepath="project.audioPath" type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
        <p v-if="project.audioPath == false" class="coming-soon">
          Coming soon...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import App from "../../App.vue";
export default {
  props: {
    projectsTitle: String,
    projects: Object,
  },
  methods: {
    loadCoverImages: () => {
      App.loadImages("#projects .cell > a > .img-and-text > img");
    },
  },
  mounted() {
    this.loadCoverImages();
  },
};
</script>

<style lang="scss" scoped>
* {
  background-color: unset;
}
h5 {
  margin-bottom: 2%;
  font-size: 1.3rem;
}
#projects {
  max-width: 100%;
  text-align: start;
  margin-bottom: 30px;
  #grid {
    display: grid;
    grid-template-columns: 12fr;
    grid-gap: 25px;
    .cell {
      display: flex;
      flex-direction: column;
      align-items: left;
      .project-link {
        text-decoration: none;
      }
      .title {
        font-size: 0.75rem;
        font-weight: bold;
        margin-bottom: 2%;
      }
      .img-and-text {
        height: 90px;
        display: flex;
        img {
          height: 90px;
          width: 90px;
          margin-right: 10px;
        }
        .details {
          height: 100%;
          padding: 5px;
          // background-color: $matte;
          overflow: auto;
          .sub {
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 3%;
          }
          .desc {
            font-size: 0.7rem;
            margin-bottom: 0;
          }
        }
      }
      audio {
        height: 30px;
        width: 100%;
        margin-top: 3%;
      }
    }
  }
  @media only screen and (min-width: $minLaptopW) {
    #grid {
      grid-template-columns: 6fr 6fr;
    }
  }
}
</style>