<template>
  <div id="signup">
    <input type="email" placeholder="Email" v-model="email" />
    <input type="text" placeholder="Password" v-model="pw" />
    <button @click="doSignup">Become a XINNER</button>
    <button @click="doGoogleSignup">Join with Google</button>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      pw: "",
    };
  },
  methods: {
    doGoogleSignup() {
      console.log("google sing in tutu");
      console.log("email:", this.email, "password:", this.pw);
    },
    doSignup() {
      createUserWithEmailAndPassword(getAuth(), this.email, this.pw)
        .then((data) => {
          console.log("data from createUserWithEmailAndPassword:", data);
          this.$router.push("/");
        })
        .catch((error) => {
          console.log("errorCode:", error.code);
          alert(error.message);
        });
    },
  },
};
</script>