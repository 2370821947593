<template>
  <div id="showreel" class="popup hide">
    <iframe
      src="https://drive.google.com/file/d/1VdiZ7WRBMIG6sIiKRmyqebZKu4WYemGs/preview"
      allow="autoplay"
      allowfullscreen
    ></iframe>
  </div>
  <div class="blur-screen hide" @click="this.closePopup">
    <i class="fa-solid fa-x"></i>
  </div>
</template>

<script>
import App from "../../App.vue";
export default {
  methods: {
    closePopup: App.closePopup,
  },
};
</script>

<style lang="scss" scoped>
#showreel {
  box-shadow: 0 0 10px 99999px rgba(0, 0, 0, 0.313) inset;
  width: fit-content;
  height: 70vh;
}
iframe {
  height: 100%;
  width: calc(70vh * 9 / 16);
}
.fa-x {
  height: 5vh;
  color: $mainColor1;
  position: absolute;
  top: 0;
  right: -0;
  padding: 4%;
  cursor: pointer;
}
</style>